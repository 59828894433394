<template>
  <div class="relative md:p-4 md:py-[20px] rounded-lg bg-slate-900 overflow-hidden">
    <section>
      <div
        class="content px-4 pt-0 pb-6 md:p-0"
        :class="{
          'pointer-events-none': isSubmitting || stopOrders,
          'opacity-20': stopOrders
        }"
      >
        <div class="flex align-items-center uppercase text-sm font-semibold mb-4 text-gray-400">
          Will the price go up or down?
        </div>
        <div class="flex">
          <div
            class="inline-flex align-items-center gap-1 sm:gap-2 bg-black/50 p-[5px] rounded-xl overflow-x-scroll scrollbar-hide w-full"
            :class="{ 'opacity-50': isSubmitting }"
          >
            <h2
              class="relative py-2 px-3 sm:px-4 w-1/2 rounded-lg font-semibold tracking-normal sm:tracking-wider text-xs sm:text-sm leading-none category-title mb-0 cursor-pointer hover:bg-green-400/20 hover:text-green-500 bg-slate-600/25 text-slate-200 text-center"
              :class="{ '!text-green !bg-green-400/20': isLong }"
              @click.prevent.stop="onTogglePosition(true)"
            >
              <div class="flex items-center justify-center text-center">
                <SvgIcon
                  width="14"
                  height="14"
                  icon="caret-up-fill"
                  class="mr-2"
                />
                <span>Up</span>
              </div>
            </h2>
            <h2
              class="relative py-2 px-3 sm:px-4 w-1/2 rounded-lg font-semibold tracking-normal sm:tracking-wider text-xs sm:text-sm leading-none category-title mb-0 cursor-pointer hover:bg-red-600/30 hover:text-red bg-slate-600/25 text-slate-200 text-center"
              :class="{ '!text-red !bg-red-500/30': !isLong }"
              @click.prevent.stop="onTogglePosition(false)"
            >
              <div class="flex items-center justify-center text-center">
                <SvgIcon
                  width="14"
                  height="14"
                  icon="caret-down-fill"
                  class="mr-2"
                />
                <span>Down</span>
              </div>
            </h2>
          </div>
        </div>
        <div class="flex align-items-center uppercase text-sm font-semibold my-4 text-gray-400">
          Wager
        </div>
        <div class="flex text-xs py-[5px] px-[5px] bg-black/50 rounded-lg">
          <div class="relative flex justify-center items-center text-sm pl-2">$</div>
          <input
            v-model="stake"
            class="block relative w-full rounded-lg py-2 px-2 mr-2 outline-none focus:ring-0 appearance-none hover:appearance-none disabled:text-gray-400 text-sm text-left transition-all duration-200 bg-transparent border-none autofill:bg-slate-900 tabular-nums"
            type="number"
            min="0.01"
            :disabled="isSubmitting"
            @keydown="onInputKeydown"
            @blur="onStakeBlur"
          >
          <ButtonButton
            theme="grey"
            class="relative flex justify-center items-center text-xs !py-[2px] !px-[8px] mr-1"
            :disabled="isSubmitting"
            @click.prevent.stop="onScaleStake(false)"
          >
            <SvgIcon
              icon="arrow-down"
              height="20"
              width="20"
              class="transition"
            />
          </ButtonButton>
          <ButtonButton
            theme="grey"
            class="relative flex justify-center items-center text-xs !py-[4px] !px-[8px]"
            :disabled="isSubmitting"
            @click.prevent.stop="onScaleStake(true)"
          >
            <SvgIcon
              icon="arrow-down"
              height="20"
              width="20"
              class="transition rotate-180"
            />
          </ButtonButton>
        </div>
        <div class="flex align-items-center uppercase text-sm font-semibold my-4 text-gray-400">
          Payout Multiplier
        </div>
        <div class="flex text-xs mb-4">
          <div class="flex w-2/3 bg-black/50 rounded-lg py-[5px] px-[5px]">
            <div class="relative flex justify-center items-center text-sm pl-2 mr-2">&times;</div>
            <input
              v-model="leverage"
              class="block relative w-full rounded-lg py-2 px-2 mr-2 outline-none focus:ring-0 appearance-none hover:appearance-none disabled:text-gray-400 text-sm text-left transition-all duration-200 bg-transparent border-none autofill:bg-slate-900 tabular-nums"
              type="number"
              min="1"
              :max="asset.leverageLimit"
              step="1"
              :disabled="isSubmitting || stopOrders"
              @keydown="onInputKeydown"
              @input="onLeverageInput"
            >
          </div>
          <div class="flex flex-col w-1/3 align-center justify-center align-items-center grow">
            <span class="text-gray-400 text-sm pl-2">Bust Price:</span>
            <span class="pl-2 text-sm tabular-nums">
              {{ (stake && leverage) ? bustPrice : '--' }}
            </span>
          </div>
        </div>
        <div class="mb-4">
          <input
            v-model="leveragePercentage"
            type="range"
            class="w-full h-3 rounded-lg appearance-none cursor-pointer range-lg bg-gradient-to-r from-green via-yellow to-red [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-[25px] [&::-webkit-slider-thumb]:h-[25px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white [&::-webkit-slider-thumb]:shadow-2xl disabled:opacity-50"
            :min="1"
            :max="100"
            :disabled="isSubmitting || stopOrders"
            @input.prevent="onLeverageSliderChange"
          >
          <div class="flex align-center justify-between text-sm my-2">
            <span>x1<span class="mx-1">·</span><span class="text-green font-bold">Low</span></span>
            <span><span class="text-red font-bold">High</span><span class="mx-1">·</span>x{{ asset.leverageLimit }}</span>
          </div>
        </div>
        <p v-if="!!notionalError" class="mb-4 p-2 bg-red-500/20 border border-red-500/50 text-red-500 rounded-md text-sm">
          {{ notionalError }}
        </p>
        <div class="w-full">
          <ButtonButton
            class="w-full !py-3"
            theme="dark"
            :class="{
              '!text-black !bg-green-500/90 hover:!bg-green-400/90 disabled:!bg-green-500/50': isLong,
              '!text-black !bg-red-500/90 hover:!bg-red-400/90 disabled:!bg-red-500/50': !isLong
            }"
            :disabled="!Number(stake) || !leverage || stopOrders || isSubmitting || stopOrders || !!notionalError"
            :is-loading="isSubmitting"
            @click.prevent="onSubmit"
          >
            <span class="w-full" :class="{ 'text-transparent': isSubmitting }">
              Place Bet
            </span>
          </ButtonButton>
        </div>
      </div>
      <section v-if="stopOrders" class="absolute bg-slate-900/80 top-0 right-0 bottom-0 left-0 rounded-lg">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center mx-auto">
          <SvgIcon
            :icon="asset.code"
            height="35"
            width="35"
            class="icon inline-block rounded mb-4"
          />
          <h4 class="text-xl leading-tight mb-2 font-bold whitespace-nowrap">
            Betting Disabled
          </h4>
          <p class="mb-4 text-xs">
            Bets are currently restricted. Please try again later.
          </p>
        </div>
      </section>
    </section>
  </div>
</template>

<script setup>
import { calculateBustPrice } from '@/utils/trading/calculateBustPrice';
import { calculateAggregateNotionalLimit } from '@/utils/trading/calculateNotionalLimit';

const props = defineProps({
  assetCode: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'order-added',
]);

const websocketStore = useWebsocketStore();
const tradingStore = useTradingStore();

const {
  addOrder,
  findAsset,
} = tradingStore;

const {
  openOrders,
  activeOrderTab,
  preferences,
} = storeToRefs(tradingStore);

const bankingStore = useBankingStore();

const {
  currentCurrency,
  currentContext,
} = storeToRefs(bankingStore);

const uiStore = useUiStore();

const {
  toastNotification,
} = storeToRefs(uiStore);

const {
  $gtmCustomEvent,
  $toFixed,
  $toCurrencyString,
} = useNuxtApp();

const assetCode = ref(props.assetCode);
const asset = computed(() => findAsset(assetCode.value));
const saved = ref(preferences.value.form[assetCode.value]);
const orderType = ref(saved.value?.orderType || 'Manual');
const isLong = ref(saved.value?.isLong ?? true);
const stake = ref($toCurrencyString(saved.value?.stake || 10, 'USD', 2, false));
const leverage = ref(saved.value?.leverage || 10);
const leveragePercentage = ref(saved.value?.leverage && saved.value?.leveragePercentage ? saved.value?.leveragePercentage : 25);
const bustPrice = ref(0);
const isSubmitting = ref(false);
const stopOrders = ref(asset.value.stopOrders);
const notionalLimit = ref(asset.value.notionalLimit);
const notionalUserLimit = ref(asset.value.userNotionalLimit);
const notionalError = computed(() => checkNotionalLimits());
const leverageSteps = ref([
  { percent: 0, value: 1, },
  { percent: 12.5, value: asset.value.leverageLimit * 0.002, },
  { percent: 25, value: asset.value.leverageLimit * 0.01, },
  { percent: 37.5, value: asset.value.leverageLimit * 0.02, },
  { percent: 50, value: asset.value.leverageLimit * 0.05, },
  { percent: 62.5, value: asset.value.leverageLimit * 0.1, },
  { percent: 75, value: asset.value.leverageLimit * 0.2, },
  { percent: 87.5, value: asset.value.leverageLimit * 0.5, },
  { percent: 100, value: asset.value.leverageLimit, },
]);

init();

function init() {
  websocketStore.$onAction(({ name, args, }) => {
    if (name !== 'handleMessage') {
      return;
    }

    const type = args[0].type;

    switch (type) {
      case 'Trading:Asset:StopOrders':
      case 'Trading:Asset:ResumeOrders':
        useMessageHandler({ name, args, }, onToggleStopOrders, type);
        break;
    }
  });

  if (!saved.value) {
    updatePreferences();
  }
}

function onToggleStopOrders() {
  stopOrders.value = !stopOrders.value;
}

function onTogglePosition(val) {
  isLong.value = val;
}

function onInputKeydown(event) {
  if (event.key === '-') {
    event.preventDefault();
  }
}

function onLeverageInput() {
  if (leverage.value >= asset.value.leverageLimit) {
    leverage.value = asset.value.leverageLimit;
  }

  const matched = leverageSteps.value.reduce((prev, curr) => {
    return Math.abs(curr.value - leverage.value) < Math.abs(prev.value - leverage.value) ? curr : prev;
  });

  if (matched.percent !== leveragePercentage.value) {
    leveragePercentage.value = matched.percent;
  }
}

function onScaleStake(increase) {
  if (!Number(stake.value)) {
    stake.value = '10.00';
    return;
  }

  stake.value = increase ? $toFixed(Number(stake.value) * 2) : $toFixed(Number(stake.value) / 2);

  if (stake.value > 100000) {
    stake.value = '100000';
  }
}

function onLeverageSliderChange() {
  const percent = leveragePercentage.value;
  const matched = leverageSteps.value.reduce((prev, curr) => {
    return Math.abs(curr.percent - percent) < Math.abs(prev.percent - percent) ? curr : prev;
  });

  if (matched.percent !== leveragePercentage.value) {
    leveragePercentage.value = matched.percent;
    leverage.value = matched.value;
  }
}

async function onSubmit() {
  if (stopOrders.value) {
    return;
  }

  try {
    isSubmitting.value = true;

    const order = {
      type: orderType.value,
      assetCode: assetCode.value,
      isLong: isLong.value,
      leverage: leverage.value,
      stake: {
        baseAmount: stake.value,
        fundsCurrencyCode: currentCurrency.value,
        fundsContext: currentContext.value,
      },
    };

    const added = await addOrder(order);
    updatePreferences();
    activeOrderTab.value = 'active';
    emit('order-added', added);

    toastNotification.value = {
      type: 'success',
      title: 'Bet Placed',
      content: `Wager: $${added.stake.baseAmount}, Multiplier: x${$toFixed(added.leverage)}, Bust Price: ${added.bustPrice}`,
      closeAfter: 3000,
    };

    $gtmCustomEvent({
      event: 'trading_order_added',
      action: 'success',
      order,
    });
  } catch (err) {
    handleError(err);
  } finally {
    isSubmitting.value = false;
  }
}

function handleError(err) {
  let message = [400, 403,].includes(err?.data?.status) ? err?.data?.message : 'There was an error placing your bet.';

  if (err?.data.name === 'InsufficientFunds') {
    message = 'Insufficient funds to place bet.';
  }

  if (err?.data?.code) {
    switch (err.data.code) {
      case 1002:
        message = 'Trading asset is disabled. Please try again later.';
        break;

      case 1005:
        message = 'You have exceeded the maximum bet limit (wager x multiplier).';
        break;

      case 1006:
        message = 'You have exceeded the maximum aggregate bet limit.';
        break;

      case 1007:
        message = 'Bets are currently disabled. Please try again later.';
        break;

      case 1008:
        message = `${assetCode.value} has reached the maximum number of active bets.`;
        break;

      case 1009:
        message = `You have reached your maximum number of active bets for ${assetCode.value}.`;
        break;

      default:
        message = 'There was an error placing your bet.';
        break;
    }
  }

  toastNotification.value = {
    type: 'error',
    title: 'Bet Failed',
    content: message,
    closeAfter: 5000,
  };
}

function updatePreferences() {
  preferences.value.form[assetCode.value] = {
    orderType: orderType.value,
    isLong: isLong.value,
    stake: Number(stake.value || 10),
    leverage: leverage.value,
    leveragePercentage: leveragePercentage.value,
  };
}

function checkNotionalLimits() {
  const currentOrderNotional = (stake.value * leverage.value);

  if (stake.value > 0 && currentOrderNotional < 1) {
    return 'Minimum position is $1 (wager x multiplier)';
  }

  if (currentOrderNotional > notionalLimit.value) {
    return `Maximum position is ${$toCurrencyString(notionalLimit.value, 'USD', 0)} (wager x multiplier)`;
  }

  const orders = openOrders.value.filter(o => o.assetCode === assetCode.value);

  if (orders.length > 0) {
    const runningOrderNotional = calculateAggregateNotionalLimit(orders);
    const newAggregateNotional = currentOrderNotional + runningOrderNotional;

    if (newAggregateNotional > notionalUserLimit.value) {
      return `Maximum aggregate position is ${$toCurrencyString(notionalUserLimit.value, 'USD', 0)}`;
    }
  }

  return null;
}

watchEffect(() => {
  const value = calculateBustPrice(asset.value, isLong.value, leverage.value);
  bustPrice.value = $toFixed(value.toNumber(), asset.value.decimals);
});

watch(() => stake.value, (value, old) => {
  if (value > 100000) {
    stake.value = old;
  }
});
</script>
